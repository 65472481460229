import React from "react";
import { useTranslation } from "react-i18next";

const ContactInfo = () => {
  const { t } = useTranslation("contact");

  return (
    <>
      <div className="contact-info">
        <h4>{t("title")}</h4>
        <p>{t("description")}</p>

        <ul>
          <li className="media">
            <i className="icon icon-envelope"></i>
            <span className="media-body">yoann.le-hir@orange.fr</span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-phone"></i>
            <span className="media-body">+33 6 21 87 64 21</span>
          </li>
          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
