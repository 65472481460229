import React from "react";
import { useTranslation } from "react-i18next";
import Skills from "../skills/Skills";
import TechStacks from "../../components/award/TechStacks";

const Resume = () => {
  const { t } = useTranslation("resume");

  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>{t("title")}</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {t("jobs", { returnObjects: true }).map((val, i) => (
              <div className="resume-row" key={i}>
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h6>{val.jobPosition}</h6>
                      <label>{val.jobType}</label>
                      <p>{val.jobDuration}</p>
                      <div className="rb-time">{val.timeDuration}</div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.companyName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>{t("skills")}</h3>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 m-15px-tb">
              <div className="aducation-box">
                <TechStacks />
              </div>
            </div>
            {/* End .col */}

            <div className="col-lg-7 ml-auto m-15px-tb">
              <Skills />
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
