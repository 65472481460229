import React, { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const PortfolioTemplate = ({
  img = "mockup",
  title,
  description,
  stack,
  order = 0,
  urlApple,
  urlGoogle,
}) => {
  const [hover, setHover] = useState(false);
  const handle = useFullScreenHandle();

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        boxShadow: hover
          ? "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
          : "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        borderRadius: 5,
      }}
      className="row mb-5"
    >
      <div onClick={handle.enter} className={`col-md-6 px-0 order-${order}`}>
        <FullScreen handle={handle}>
          <img
            src={require(`./../../assets/images/work/${img}`)}
            alt="mockup"
          />
        </FullScreen>
      </div>

      <div className="col-md-6 px-3 mb-4">
        <h3 className="mt-4 mb-3">{title}</h3>
        {description &&
          description.map((des, i) => (
            <React.Fragment key={i}>{des}</React.Fragment>
          ))}
        <h5
          className="mb-3 mt-4"
          style={{ fontWeight: "bold", marginLeft: "8px" }}
        >
          Stack
        </h5>
        <div
          style={{ flexDirection: "row", display: "flex", flexWrap: "wrap" }}
        >
          {stack &&
            stack.map((e, i) => (
              <div
                key={i}
                className="mx-2 mb-2"
                style={{
                  boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  borderRadius: 5,
                }}
              >
                <p className="px-3 py-1 mr-4 my-1">{e} </p>
              </div>
            ))}
        </div>
        <div
          className="mt-2 px-2"
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          {urlGoogle && (
            <a href={urlGoogle}>
              <img
                height={40}
                style={{ marginRight: 10 }}
                src={
                  require(`./../../assets/images/store_logo/Google_Play_Store_badge_FR.svg`)
                    .default
                }
                alt="télécharger avec Google"
              />
            </a>
          )}

          {urlApple && (
            <a href={urlApple}>
              <img
                height={40}
                src={
                  require(`./../../assets/images/store_logo/apple_white.svg`)
                    .default
                }
                alt="télécharger avec Apple"
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioTemplate;
