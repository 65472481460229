import React from "react";
import PortfolioTemplate from "./PortfolioTemplate";
import { useTranslation } from "react-i18next";

const Portfolio = () => {
  const { t } = useTranslation("portfolio");

  const renderFeatures = (key) => {
    return Array.isArray(t(`${key}.features.list`, { returnObjects: true }))
      ? t(`${key}.features.list`, { returnObjects: true }).map(
          (feature, index) => (
            <span key={index} style={{ marginRight: "8px" }}>
              • {feature}
            </span>
          )
        )
      : null;
  };

  return (
    <div>
      <PortfolioTemplate
        title="Lyynk"
        img="lyynk-cover.jpg"
        description={[
          <p>
            <b>{t("lyynk.duration")}</b> {t("lyynk.company")} <br />
            {t("lyynk.description")}
          </p>,
          <p>
            <b>{t("lyynk.features.title")}</b>: <br />
            {renderFeatures("lyynk")}
          </p>,
        ]}
        stack={[
          "Expo",
          "Firebase cloud firestore",
          "Firebase cloud function",
          "Docker",
        ]}
        urlApple="https://apps.apple.com/fr/app/lyynk/id6478778328"
        urlGoogle="https://play.google.com/store/apps/details?id=com.lyynk.app"
      />

      <PortfolioTemplate
        title="SmartBarrel"
        img="smartbarrel-cover.jpg"
        description={[
          <p>
            <b>{t("smartbarrel.duration")}</b> {t("smartbarrel.company")} <br />
            {t("smartbarrel.description")}
          </p>,
          <p>
            <b>{t("smartbarrel.features.title")}</b>: <br />
            {renderFeatures("smartbarrel")}
          </p>,
        ]}
        stack={[
          "Expo",
          "Expo Location",
          "React native maps",
          "Sentry",
          "Jest",
          "Rest Api",
        ]}
        urlApple="https://apps.apple.com/us/app/smartbarrel-mobilepunch/id1527784288?platform=iphone"
        urlGoogle="https://play.google.com/store/apps/details?id=com.mobilepunch"
      />

      <PortfolioTemplate
        title="KlubX - Maaser Labs"
        img="klubx4.jpg"
        description={[
          <p>
            <b>{t("klubx.duration")}</b> {t("klubx.company")} <br />
            {t("klubx.description")}
          </p>,
          <p>
            <b>{t("klubx.features.title")}</b>: <br />
            {renderFeatures("klubx")}
          </p>,
          <p>{t("klubx.note")}</p>,
        ]}
        stack={["Expo", "Sentry", "Jest", "Rest Api", "Socket.io"]}
        urlApple="https://apps.apple.com/lu/app/klubx/id1644886921"
        urlGoogle="https://play.google.com/store/apps/details?id=space.klubx&hl=en_US"
      />

      <PortfolioTemplate
        title="Prions en Eglise - Bayard"
        img="prions3.jpg"
        description={[
          <p>
            <b>{t("prions.duration")}</b> {t("prions.company")} <br />
            {t("prions.description")}
          </p>,
          <p>
            <b>{t("prions.features.title")}</b>: <br />
            {renderFeatures("prions")}
          </p>,
          <p>{t("prions.note")}</p>,
        ]}
        stack={[
          "React Native CLI",
          "AppCenter",
          "Android Studio",
          "XCode",
          "GitLab",
        ]}
        urlApple="https://apps.apple.com/fr/app/prions-en-eglise/id394393743"
        urlGoogle="https://play.google.com/store/apps/details?id=com.groupebayard.prionseneglise.iphone&hl=fr&gl=US"
      />

      <PortfolioTemplate
        title="OneTribe"
        img="onet2.png"
        description={[
          <p>
            <b>{t("onetribe.title")}</b> {t("onetribe.description")}
          </p>,
          <p>
            <b>{t("onetribe.features.title")}</b>: <br />
            {renderFeatures("onetribe")}
          </p>,
        ]}
        stack={[
          "React-Native",
          "Expo",
          "NodeJS",
          "Express",
          "Firebase",
          "Heroku",
          "Amazon AWS",
          "Sentry",
        ]}
        urlApple="https://apps.apple.com/us/app/onetribe/id1584177701"
        urlGoogle="https://play.google.com/store/apps/details?id=fr.onetribe"
      />

      <PortfolioTemplate
        title="Ulteam App"
        img="ulteamt.png"
        description={[
          <p>
            <b>{t("ulteam.title")}</b> {t("ulteam.description")}
          </p>,
          <p>
            <b>{t("ulteam.features.title")}</b>: <br />
            {renderFeatures("ulteam")}
          </p>,
        ]}
        stack={[
          "React-Native",
          "Expo",
          "WebView",
          "Animated",
          "React-Query",
          "React Hook Form",
          "Redux",
        ]}
        urlApple="https://apps.apple.com/fr/app/ulteam-tv/id1584616773"
        urlGoogle="https://play.google.com/store/apps/details?id=io.gonative.android.womypo"
        order={1}
      />

      <PortfolioTemplate
        title="DeliverPop"
        img="deliverpop2.png"
        description={[
          <p>
            <b>{t("deliverpop.title")}</b> {t("deliverpop.description")}
          </p>,
          <p>
            <b>{t("deliverpop.features.title")}</b>: <br />
            {renderFeatures("deliverpop")}
          </p>,
        ]}
        stack={[
          "React-native",
          "Expo",
          "Redux",
          "React-hook-form",
          "Reanimated",
        ]}
      />

      {/* Continue with other PortfolioTemplate components similarly */}
    </div>
  );
};

export default Portfolio;
