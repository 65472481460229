import React from "react";
import Header from "../components/header/Header";

import Resume from "../components/resume/Resume";
import Portfolio from "../components/portfolio/Portfolio";
import Contact from "../components/contact/Contact";
import ContactInfo from "../components/contact/ContactInfo";
import Footer from "../components/footer/Footer";
import useDocumentTitle from "../components/useDocumentTitle";
import Introduction from "../components/slider/Introduction";
import { LanguageSwitch } from "../components/header/LanguageSwitch";
import { useTranslation } from "react-i18next";

const HomeLightSidebarTwo = () => {
  useDocumentTitle("Portfolio - Yoann Le Hir");
  document.body.classList.add("theme-light");
  const { t } = useTranslation("categories");

  return (
    <div className="main-left">
      <Header />
      <LanguageSwitch />

      {/* End Header Section */}

      <Introduction />
      {/* End Slider Section */}

      <section id="work" className="section theme-light dark-bg">
        <div className="container">
          <div className="title">
            <h3>{t("achievements")}</h3>
          </div>
          <Portfolio />
        </div>
      </section>
      {/* End Portfolio Section */}

      <Resume />
      {/* End Resume Section */}

      {/* End Portfolio Section */}

      <section id="contactus" className="section theme-light dark-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-4 m-15px-tb">
              <ContactInfo />
            </div>
            {/* End Contact info */}

            <div className="col-lg-7 ml-auto m-15px-tb  ">
              <div className="contact-form">
                <Contact />
              </div>
            </div>
            {/* End contact form */}

            {/* End Col */}
          </div>
        </div>
      </section>
      {/* End Contact Section */}

      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/* End Contact Section */}
    </div>
  );
};

export default HomeLightSidebarTwo;
